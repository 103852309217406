/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 8-9-2017
 * Time: 10:01
 *
 *
 */

angular.module('sihw.valuesfilter',[])
.filter('values',function() {
    return function(inObject)
    {
        if (inObject === null || inObject === undefined || Array.isArray(inObject))
        {
            return inObject; //gewoon terug
        }


        if (! angular.isObject(inObject))
        {
            throw(new TypeError("sihw.valuesfilter: input is geen object"));
        }

        //we gebruiken Object.values als die er is
        if (typeof(Object.values) === "function" )
        {
            return Object.values(inObject);
        }

        var r=[];
        angular.forEach(inObject,function(val) {
            r.push(val);
            }
        );
        return r;
    }
});