/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 12-2-2018
 * Time: 01:27
 *
 * Params:
 * id: bestaande id (of null bij nieuw) OF
 * kopie: id van bestaande waarmee de nieuwe gevuld wordt
 *
 */

angular.module('myApp.edit', [
    'sihw.sihwlog',
    'dl.approuter',
    'ngFileUpload',
    'ui.bootstrap',
    'ngTagsInput',
    'quilleditor',
    'myApp.erroralert',
    'myApp.overzicht' //voor de materiaallijst
]).config(['approuterProvider', function (approuterProvider) {
    approuterProvider.state('edit',
        {
            url: '/edit/:id',
            openbaar: false, //onze eigen openbaar vlag
            templateUrl: 'states/edit/edit.html',
            controller: 'myApp.edit.controller'
        });
    approuterProvider.state('editnieuw',
        {
            url: '/editnieuw/:kopie',
            openbaar: false, //onze eigen openbaar vlag
            templateUrl: 'states/edit/edit.html',
            controller: 'myApp.edit.controller'
        })
}])
    .controller('myApp.edit.controller', ['$scope', '$stateParams', '$translate', '$timeout','approuter', 'sihwlog', 'api', 'erroralert',
        function ($scope, $stateParams, $translate, $timeout, approuter, sihwlog, api, erroralert) {
            let log = sihwlog.logLevel('debug');
            log.log('myApp.edit.controller');
            approuter.menutitel("EDIT.HEADER"); //tot aan de titel


            //okee?
            if (!$scope.basis.userdata.write) {
                //niets te zoeken hier
                approuter.go('splash');
                return;
            }

            //als je wel write hebt, maar niet voor dit material, dan merken we dat wel bij het opslaan

            $scope.model = {
                actueel: true,
                lesdirect: null,
                zelf: true, //zijn we eigenaar? Bij nieuw wel
                taal: $translate.use(), //standaard onze taal
                doc: [],
                model: [],
                editors: [],
                voorkennis: [],
                schooltypen: [],
                grade: {schooltype: 'Grade', leerjaar: {nvt: false}},
                modelleerniveau: {nvt: true}
            };
            $scope.nieuwschooltype = {
                schooltype: "",
                leerjaar: {}
            } //voor toevoegen
            $scope.nieuw = !$stateParams.id;
            $scope.titel = "";


            //nu ophalen, maar één keer (want bij kopie willen we geen reload)
            if ($stateParams.kopie) {
                log.debug(`Openen als kopie`);
            }
            log.debug($stateParams);

            //init editors en andere dom-zaken
            //dit doen we op viewcontentloaded en daarna nog even lucht
            $scope.$watch('$viewContentLoaded', function(){
                setTimeout(() => {
                    log.debug(`VIEW CONTENT LOADED`);
                    //verplaats de id van de tags naar de onderliggende input
                    //als de dom er helemaal is
                    $('[data-id-naar-input]').each((i, e) => {
                        e = $(e);
                        let id = e.attr('data-id-naar-input');
                        log.debug(`Verplaats id ${id}`, e.find('input'));
                        e.find('input').attr('id', id);
                    });
                });

            });
            ((!($stateParams.id || $stateParams.kopie)) ? haalTaallijsten() : haalData()).then(() => {
                //haal de basisdata
                //haal de mimetypes
                return api.cachedSend([], 'material', 'mimetypes').then(mimetypes => {
                    //we willen het kommagescheiden
                    for (let doctype of Object.keys(mimetypes)) {
                        mimetypes[doctype] = mimetypes[doctype].join(',');
                    }
                    $scope.mimetypes = mimetypes;
                });
            })/*.then(() => {
                //modellen niet via cache?
                return api.send('material', 'modelLijst')
            }).then(res => {
                $scope.modellen = res && res.modellen || [];
            })*/.then(() => {
                return api.send('material', 'auteurslijst')
            }).then(res => {
                $scope.auteurs = res && res.auteurs || []
            }).then(() => {
                return api.send('material', 'schooltypelijst')
            }).then(res => {
                $scope.schooltypen = (res && res.schooltypen || []).map(typerij => typerij.schooltype).filter(schooltype => schooltype !== 'Grade'); //grade uitfilteren
                log.debug(`schooltypen`, res.schooltypen);
            }).then(() => {
                //mogelijke editors, worden altijd gehaald. Ook als we geen eigenaar zijn en het niet mogen veranderen
                //dat is simpeler en het kan toch cached
                return api.cachedSend([], 'material', 'editorlijst');
            }).then(res => {
                $scope.editors = res && res.editors || [];
                log.debug($scope.editors);
            }).catch(erroralert);


            function haalData() {
                let id = null;
                if ($stateParams.kopie) {
                    id = $stateParams.kopie; //hier moet een kopie van komen
                } else if ($stateParams.id) {
                    id = $stateParams.id; //anders gewoon deze laden en bewerken
                } else {
                    approuter.mainScreen();
                    return; //wat doen we hier?
                }
                return api.cachedSend(['materiaal', `materiaal:${id}`], 'material', 'detail', {id: id}).then(res => {
                    if ($stateParams.kopie) {
                        //als we geen editor zijn van het materiaal mag het niet
                        //backend gaat dan kopiebron niet pikken
                        if (!res.detail.editor) {
                            approuter.mainScreen();
                            return; //wat doen we hier?
                        }
                        //dus niet deze id bewerken
                        delete res.detail.id;
                        res.detail.zelf = true; //want nieuw
                        res.detail.titel = `${res.detail.titel} (${$translate.instant('ALG.KOPIE')})`;
                    }
                    $scope.model = res.detail;
                    $scope.model.werkid = id; //kan de id of de kopie-id zijn, voor laden van voorkennis etc.
                    $scope.titel = res.detail.titel; //zolang niet wordt opgeslagen

                    //schooltypen en leerjaren
                    //Grade moet eruit, die gaat apart
                    $scope.model.schooltypen = ($scope.model.schooltypen || []).map(
                        st => {
                            let typerij = {
                                schooltype: st.schooltype
                            };
                            typerij.leerjaar = {
                                nvt: st.leerjaar === "" //lege string = NVT (null = niet aangegeven)
                            };
                            for (let lj of ((st.leerjaar && st.leerjaar.length) ? st.leerjaar.split(/\s*,\s*/) : [])) {
                                typerij.leerjaar[lj] = true; //voor de knoppen
                            }

                            return typerij;
                        }
                    );
                    $scope.model.grade = $scope.model.schooltypen.find(st => st.schooltype === 'Grade'); //apart
                    if (! $scope.model.grade)
                    {
                        $scope.model.grade = {schooltype: 'Grade', leerjaar: {nvt: true}};
                    }
                    $scope.model.schooltypen = $scope.model.schooltypen.filter(st => st.schooltype !== 'Grade'); //en uit de gewone

                    let niveaus = res.detail.modelleerniveau && res.detail.modelleerniveau.length ? res.detail.modelleerniveau.split(/\s*,\s*/) : [];
                    $scope.model.modelleerniveau = {
                        nvt: res.detail.modelleerniveau === "" //zelfde als bij leerjaar
                    }
                    for (let niveau of niveaus) {
                        $scope.model.modelleerniveau[niveau] = true;
                    }
                    approuter.menutitel(`${$scope.titel} - ${$translate.instant('EDIT.HEADER')} `);
                    for (let doc of $scope.model.doc) {
                        doc.url = api.getUrl("material", "doc", [doc.id, $scope.model.token, doc.naam], false); //naam erachter voor de mooi bij download
                    }
                    for (let model of $scope.model.model) {
                        model.img = model.heeftimage && api.getUrl('material', 'modelimg', [model.id,$scope.model.token, model.titel], false);
                    }

                    //verwerk rich text als dat het nog niet is
                    for(let key of ['omschrijving','leerdoel','werkwijze'])
                    {
                        log.debug(key, $scope.model[key]);
                        if ($scope.model[key] && (! $scope.model[key].includes('<')))
                        {
                                //geen html
                                $scope.model[key] = $scope.model[key].replace(/(\r\n|\n\r|\n|\r)/g, "<br>");
                                log.debug('--> ', $scope.model[key]);
                            }
                    }
/*                    $timeout(_ => {
                        $scope.model.omschrijving = "Nu is het anders";
                        log.debug(`Omschrijving gewijzigd`);
                    },5000);*/

                    return haalTaallijsten(); //want taal (haalt vakken op)
                }).catch(err => {
                    erroralert(err).then(() => {
                        approuter.go('splash'); //hier weg
                    });
                })
            }

            function haalTaallijsten() {
                if (!$scope.model.taal) {
                    return;
                }

                return api.cachedSend(['materiaal'], 'material', 'taallijsten', {taal: $scope.model.taal}).then(res => {
                    //  $scope.schooltypelijst = res.schooltypes;
                    $scope.vaklijst = res.vakken;
                }).catch(angular.noop);
            }

            $scope.taalgewijzigd = haalTaallijsten;

            $scope.autocompleteAuteurs = function (query) {
                query = query.toLocaleLowerCase();
                return ($scope.auteurs || []).filter(au =>
                    au.auteur.toLocaleLowerCase().includes(query)
                );
            }

            $scope.autocompleteEditors = function (query) {
                query = query.toLocaleLowerCase();
                return ($scope.editors || []).filter(ed =>
                    ed.id !== $scope.model.user_id &&
                    ed.naam.toLocaleLowerCase().includes(query));
            }

            /**
             * Toggle het modelleerniveau. Via het model wordt de knop geactiveerd
             * @param niveau
             */
            $scope.toggleModelleerniveau = function (niveau) {
                $scope.model.modelleerniveau = ($scope.model.modelleerniveau === niveau ? null : niveau);
            }
            /**
             * Zet een waarde in een multi-array voor een key uit of aan
             * @param key
             * @param waarde
             */
            $scope.toggleMulti = function (key, waarde) {
                if ($scope.model[key]) {
                    $scope.model[key][waarde] = !$scope.model[key][waarde];
                    $scope.model[key].nvt = false; //niet meer nvt
                }
                log.debug($scope.model[key]);
            }

            /**
             * Regel het toggelen van NVT
             * @param key
             */
            $scope.toggleMultiNVT = function (key) {
                let nvt = $scope.model[key].nvt;
                //al het andere sowieso  uit
                $scope.model[key] = {nvt: !nvt};
            }

            /////// bij leerjaren gaat het apart, omdat die in schooltype zitten

            /**
             * Return een array met schooltypen voor typeahead
             */
            $scope.schooltypelijst = function () {
                return $scope.schooltypen.filter(st => !$scope.model.schooltypen.some(typerij => typerij.schooltype === st));
            };

            $scope.toggleSchooltypeNVT = function (typerij) {
                typerij.leerjaar = {nvt: !typerij.leerjaar.nvt}; //al het andere uit
            }

            $scope.toggleSchooltypeLeerjaar = function (typerij, leerjaar) {
                log.debug(`toggleSchooltypeLeerjaar`,leerjaar,typerij, $scope.model.grade);
                if (!typerij.leerjaar[leerjaar]) {
                    //die gaat aan, dus nvt uit
                    typerij.leerjaar.nvt = false;
                    typerij.leerjaar[leerjaar] = true;
                } else {
                    typerij.leerjaar[leerjaar] = false;
                }
            }

            /**
             * Voeg de rij met nieuwe data gewoon toe aan de schooltypen
             */
            $scope.toevoegenSchooltype = function () {
                $scope.model.schooltypen.push($scope.nieuwschooltype); //overnemen
                $scope.nieuwschooltype = {
                    schooltype: "",
                    leerjaar: {}
                }; //een nieuwe
            }
            /**
             * Verwijder een specifieke typerij
             * @param typerij
             */
            $scope.verwijderSchooltype = function (typerij) {
                $scope.model.schooltypen.splice($scope.model.schooltypen.indexOf(typerij), 1);
            }

            $scope.toggleDocverwijder = function (doc) {
                //als een upload, dan kan hij weg
                if (doc.upload) {
                    $scope.model.doc.splice($scope.model.doc.indexOf(doc), 1);
                } else {
                    //toggle verwijder
                    doc.verwijderd = !doc.verwijderd;
                }
            };

            /**
             * Voeg een nieuw document toe via upload
             * @param file
             * @param doctype 'doc' of 'img'
             * @param naamprop $scope prop (ng-model-naam) voor de naam
             */
            $scope.nieuwDoc = function (file, doctype, naamprop) {
                log.debug(`nieuwDoc`, file, doctype, naamprop);
                if (!file) {
                    return; //gaat soms mis
                }
                let doc = {
                    // upload: file,
                    doctype: doctype,
                    //mimetype wordt door het be geregeld
                    naam: $scope[naamprop] || file.name || $translate.instant('EDIT.DOCUMENT')
                };
                //we vullen doc.upload even async
                file.arrayBuffer().then(buffer => doc.upload = buffer);
                $scope.model.doc.unshift(doc);
                $scope[naamprop] = "";
                doc.url = URL.createObjectURL(file); //het hele file-object
                $scope.$on('$destroy', () => {
                    log.debug('RevokeObjectURl', doc.url);
                    URL.revokeObjectURL(doc.url);
                })
            };

            $scope.toevoegenModel = function () {
                if (!$scope.nieuwModel) {
                    return;
                }

                let nieuwModel = $scope.nieuwModel;
                log.debug('toevoegen nieuwModel', nieuwModel);
                $scope.model.model.unshift({
                    idmodel: nieuwModel.idmodel,
                    titel: nieuwModel.titel,
                    nieuw: true,
                    img: $scope.appdata.getUrl + nieuwModel.img
                });
            };

            $scope.toggleModelVerwijder = function (model) {
                //als nieuw, dan kan hij weg
                if (model.nieuw) {
                    $scope.model.model.splice($scope.model.model.indexOf(model), 1);
                } else {
                    //toggle verwijder
                    model.verwijderd = !model.verwijderd;
                }
            };

            $scope.opslaan = function () {
                //vooralsnog alle data naar achteren. Validering is gedaan door ng-form

                if ($scope.nieuwschooltype.schooltype && $scope.nieuwschooltype.schooltype.length) {
                    //dan telt hij ook mee
                    $scope.toevoegenSchooltype(); //toegevoegd
                }
                let save = angular.copy($scope.model);
                log.debug(save);
                save.voorkennis = $scope.voorkennis_select && $scope.voorkennis_select.geselecteerd().map(vk => vk.id);
                //kopiebron meegeven voor de documenten, als dit een kopie is
                if ($stateParams.kopie) {
                    log.debug(`Save met kopiebron`);
                    save.kopiebron = $stateParams.kopie;
                }
                //grade
                if (save.grade && save.grade.leerjaar && (! save.grade.leerjaar.nvt) && Object.keys(save.grade.leerjaar).filter(k => save.grade.leerjaar[k]).length)
                {
                    save.schooltypen.push(save.grade);
                    //dus niet toevoegen bij nvt of geen items
                }
                delete save.grade;
                //even door de schooltypen
                for (let typerij of save.schooltypen) {

                    //leerjaar
                    if (typerij.leerjaar.nvt) {
                        typerij.leerjaar = ""; //lege string, is iets ander dan null
                    } else {
                        let jaren = [];
                        for (let jaar = 1; jaar <= 12; jaar++) {
                            if (typerij.leerjaar[jaar]) {
                                jaren.push(jaar);
                            }
                        }
                        if (jaren.length) {
                            typerij.leerjaar = jaren.join(','); //kommagescheiden opslaan
                        } else {
                            //er is niets
                            typerij.leerjaar = null;
                        }
                    }
                }
                //modelleerniveau
                if (save.modelleerniveau.nvt) {
                    save.modelleerniveau = ""; //lege string, is iets ander dan null
                } else {
                    let niveaus = [];
                    for (let niveau of Object.keys(save.modelleerniveau)) {
                        if (niveau === 'nvt') {
                            continue; //zie hierboven
                        }
                        if (save.modelleerniveau[niveau]) {
                            niveaus.push(niveau);
                        }
                    }
                    if (niveaus.length) {
                        save.modelleerniveau = niveaus.join(','); //kommagescheiden opslaan
                    } else {
                        //er is niets
                        save.modelleerniveau = null;
                    }
                }



                log.debug(`Save`, save);
                api.send('material', 'save', save).then((res) => {
                    log.debug('Klaar met opslaan');
                    approuter.go('materiaal', {id: res.id});
                }).catch(erroralert);
            }
        }])
    /**
     * Controller voor de tab-template in tags-input. Met veel gedoe kunnen we zo bij de scopedata van tag, bij modeldata, én ux-functies aan de tag hangen, zodat we ze kunnen verplaatsen
     * Let op: de tags-imput met deze attribs hebben:
     * template= "sortabletag"
     * template-scope = "{modeldata: <de array die bewerkt wordt>}"
     */
    .controller('sortabletagCtrl', ['$scope', 'sihwlog', function ($scope, sihwlog) {
    let log = sihwlog.logLevel('debug');
    $scope.tagScope = $scope.$parent.$parent; //de scope van het tagtemplate
    $scope.repeatScope = $scope.tagScope.$parent; //de scope van de ng-repeat over tags

    $scope.tagorder = function (richting) {
        let i_huidig = $scope.repeatScope.$index;
        let i_nieuw = i_huidig + richting;
        let data = $scope.tagScope.$scope.modeldata;
        let o_huidig = data[i_huidig];
        //swap:
        data[i_huidig] = data[i_nieuw];
        data[i_nieuw] = o_huidig;
    }
}]);
