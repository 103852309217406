/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 12-2-2018
 * Time: 01:27
 *
 *
 * Overzichtstate en directive
 */

angular.module('myApp.overzicht', [
    'sihw.sihwlog',
    'dl.approuter',
    'sihw.datatable',
    'myApp.erroralert',
    'sihw.targetblank'
])
    .config(['approuterProvider', function (approuterProvider) {
        approuterProvider.state('overzicht',
            {
                url: '/overview',
                openbaar: false, //onze eigen openbaar vlag
                templateUrl: 'states/overzicht/overzicht.html',
                controller: 'myApp.overzicht.Overzichtcontroller'
            })
    }])
    .controller('myApp.overzicht.Overzichtcontroller', ['approuter', 'sihwlog',
        function (approuter, sihwlog) {
            let log = sihwlog.logLevel('debug');
            log.log('myApp.overzicht.Overzichtcontroller');
            approuter.menutitel("OVERZICHT.HEADER");
        }])
    .directive('materiaallijst', ['$rootScope','$translate', '$state', 'approuter', 'sihwlog', 'api', 'erroralert', 'targetblank',
        /**
         * Materiaallijst directive. Toon een lijst met materiaal, in overzicht maar ook in keuze bij detail en edit
         * Attributes:
         *  selecteer-voor: id. Selecteer regel als het materiaal voorkennis is voor opgegeven id (selectie dus ook zetten)
         *  def-taal: standaard filter op taalcode
         *  selectie: als aanwezig en trueish wordt er geselecteerd, met behoud selectie ongeacht filter
         *  selecteeralles: als selectie true is kan hiermee de selecteerallesknop uit of aan gezet worden (standaard aan)
         *  blank: als true, dan wordt materiaal via targetblank geopend
         *  ctrl: object dat een api krijgt:
         *      geselecteerd(); geeft array met geselecteerde id's mee
         *  voorkennis-materiaal optioneel id van een ander materiaal. We tonen alle voorkennis die bij de model is opgegeven. Verder gewoon filters beschikbaar
         */
        function ($rootScope, $translate, $state, approuter, sihwlog, api, erroralert, targetblank) {

            let log = sihwlog.logLevel('debug');
            log.debug('materiaallijst directive');
            let haalgeneratie = 0;

            return {
                restrict: 'E',
                scope: {
                    selecteerVoor: '<?',
                    defTaal: '<?',
                    selectie: '<?',
                    geenselectall: '<?', //true zetten op geenselectall boven de lijst te hebben (bij selectie)
                    ctrl: '=?',
                    blank: '<?', //als true, dan wordt materiaal in blank geopend
                    voorkennisMateriaal: '<?' //een ander materiaal (id). We tonen een voorkennislijst
                },
                link: function ($scope) {

                    initScope();

                    function initScope() {
                        $scope.lijst = [];
                        // initDef(); //Doet de initiele watch wel

                        $scope.$on('api.datawijziging_verwerkt', (_e, d) => {
                            if (d.key === 'materiaal') {
                                haalData(); //opnieuw halen, lijst is gewijzigd
                            }
                        });

                        //nu kunnen we data halen. Dat doen we door de watchers te zetten. initiele watch wordt uigevoegd

                        $scope.$watchGroup(['selecteerVoor', 'voorkennisModel'], haalData);
                        $scope.$watchGroup(['defTaal', 'selectie'], initDef);
                        $scope.$watchGroup(['ctrl'], initCtrl);
                    }

                    function initDef() {

                        log.debug('overzicht met taalfilter', $scope.defTaal);
                        $scope.overzichtdef = {
                            versie: 0, //opgehoogd door async-laders
                            standaardsortering: {
                                veld: 'titel',
                                richting: 1
                            },
                            selecteer: $scope.selectie && {
                                filterSelect: false, //bewaar selectie ook voor zaken niet in filter
                                allesknop: ! $scope.geenselectall, //moet de allesknop zichtbaar zijn?
                                setProp: '_voorkennis' //dus nieuwe rijen met deze flag op true worden geselecteerd
                            },
                            velden: {
                                titel: {
                                    label: __('OVERZICHT.titel')
                                },
                                taal: {
                                    label: __('OVERZICHT.taal'),
                                    type: 'multi',
                                    format: val => $scope.$root.appdata.talen[val] || val,
                                    standaardfilter: $scope.defTaal ? [$scope.defTaal.toLowerCase()] : null
                                },
                                schooltypen: {
                                    // type: 'multi',
                                    label: __('OVERZICHT.schooltype'),
                                    format: val => {
                                        if (val && val.length)
                                        {
                                            return val.map(st => {
                                                if (st.leerjaar === "" || (! st.leerjaar))
                                                {
                                                    return st.schooltype
                                                }
                                                else {
                                                    return st.leerjaar.split(/\s*,\s*/).map(lj => `${st.schooltype} ${lj}`).join(', ');
                                                }
                                            }).join(', ');
                                        }
                                        else {
                                            return "";
                                        }
                                    }
                                },
                                vak: {
                                    type: 'multi',
                                    label: __('OVERZICHT.vak')
                                },
                                kernwoorden: {
                                    label: __('OVERZICHT.kernwoorden')
                                },
                                modelleerniveau: {
                                    label: __('OVERZICHT.MODELLEERNIVEAU'),
                                    format: val =>{
                                        if (val === "")
                                        {
                                            return __('NVT');
                                        }
                                        else if (val)
                                        {
                                            return val.split(/\s*,\s*/).map(n => __(`OVERZICHT.MODELLEERNIVEAU_${n}`)).join(', ');
                                        }
                                        else  {
                                            return "";
                                        }
                                    }
                                }
                            }
                        };
                        if ($rootScope.basis.userdata.write)
                        {
                            //dan ook meer dingen
                            $scope.overzichtdef.velden.zelf = {
                                label: __('OVERZICHT.zelf'),
                                type: 'bool'
                            };
                            $scope.overzichtdef.velden.editor = {
                                label: __('OVERZICHT.editor'),
                                type: 'bool'
                            };
                            $scope.overzichtdef.velden.actueel = {
                                label: __('OVERZICHT.ACTUEEL'),
                                type: 'bool',
                                standaardfilter: 1
                            };
                        }
                    }

                    /**
                     * Nieuwe $scope.ctrl, dus opzetten
                     * Feitelijk hetzelfde als de api van de datatable, dus dat doen we lekker
                     */
                    function initCtrl() {
                        $scope.lijstapi = $scope.lijstapi || {}; //moet bestaan
                        $scope.ctrl = $scope.lijstapi;
                    }

                    function haalData() {
                        log.debug('materiaallijst: haaldata', $scope.selecteerVoor);
                        let lokaal_haalgeneratie = ++haalgeneratie;
                        api.cachedSend('materiaal', 'material', 'lijst',
                            {
                                voorkennis_voor: $scope.selecteerVoor, //zet _voorkennis prop, mappen wij op selectie via de setProp optie van de datatable
                                voorkennis_materiaal: $scope.voorkennisMateriaal //toon alleen materiaal dat als voorkennis voor dit model kan dienen
                            }
                        ).then(res => {
                            if (lokaal_haalgeneratie === haalgeneratie) {
                                //nog courant
                                $scope.lijst = res.materiaal;
                            }
                        }).catch(e => {
                            erroralert(e)
                        });
                    }

                    /**
                     * Open het detail (over de view heen zodat we geen data reload krijgen)
                     */
                    $scope.opendetail = function (rij) {
                        if ($scope.blank) {
                            targetblank($state.href('materiaal', {id: rij.id}));
                        } else {
                            approuter.go('materiaal', {id: rij.id});
                        }
                    };

                    /**
                     * Helper voor snelle vertaling
                     * @param args
                     * @return {string|Object}
                     * @private
                     */
                    function __(...args) {
                        return $translate.instant(...args);
                    }
                },
                template: `
            <table class="table table-hover" api="lijstapi" datatable="overzichtdef"
               datatable-data="lijst"
               datatable-rijklik="opendetail(rij)"></table>
            `
            };
        }]
    );
