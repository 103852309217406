/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 20-11-2016.
 *
 * Opsomming van wat helper-constanten
 */

'use strict';

angular.module('dl.constants', [
])
	.constant('EMAIL_REGEX', /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i) //regex van rfc5322
;