/**
 * simpele attribute directive voor onze quill-editors
 *
 * <textare quill id=... ng-model=..>
 */


angular.module('quilleditor', ['sihw.sihwlog'])
    .directive('quillEditor',
        ['sihwlog', '$timeout', function (sihwlog, $timeout) {
            const log = sihwlog.logLevel('info');
            return {
                restrict: 'E',
                scope: {},
                require: 'ngModel',
                link: function ($scope, elm, attrs, ngModel) {
                    log.debug('Quill editor ', elm[0]);
                    const quill = new Quill(elm[0], {
                        theme: 'snow'
                    });

                    quill.on('text-change', () => {
                        $scope.$apply(() => {
                            let val = quill.getSemanticHTML();
                            if (val === "<p></p>") {
                                val = "";
                            }
                            ngModel.$setViewValue(val);
                        });
                    });

                    //en andersom: we moeten iets tonen
                    ngModel.$render = function () {
                        //even buiten de apply
                        void $timeout(() => {
                            if (ngModel.$modelValue) {
                                quill.clipboard.dangerouslyPasteHTML(ngModel.$modelValue);
                            } else {
                                quill.setText("");
                            }
                        });
                        // elm[0].querySelector('.ql-editor').innerHTML = ngModel.$modelValue;
                    };
                }
            };
        }]);
