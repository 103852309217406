/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 3-11-2016.
 *
 * Applicatiespecifieke laag om de spinjs2 service
 *
 * Geen directive, wel veel basisdata in $rootScope
 * Caching via sihwmemcache
 */

angular.module('dl.api', [
    'sihw.sihwlog',
    'sihw.memcache',
    'SpinJS2',
    'sihw.thenloops',
    'sihw.targetblank',
    'sihw.localecodes'
])
    .factory('api', ['$rootScope', '$q', '$timeout', 'sihwlog', 'sihwmemcache', 'spinJS2', 'thenEach', 'targetblank', 'FRONTEND_URL', 'localecodes',
        function ($rootScope, $q, $timeout, sihwlog, sihwmemcache, spinJS2, thenEach, targetblank, FRONTEND_URL, localecodes) {
            let log = sihwlog.logLevel('debug');

            let userdata; //buiten de prop, zie getUserdata etc

            //we zetten een nieuwe cachekey, en bij inloggen weer. Dan wordt dus alles geclear

            //basis wordt gevuld met alle data die we courant bij de hand hebben mbt een ingelogde user.
            let basis = $rootScope.basis = {
                ingelogd: false
            }; //gebruiken we in de rootscope

            //appdata zijn zaken die niet userafhankelijk zijn, maar toch in de rootscope moeten
            let appdata = $rootScope.appdata = {
                getUrl: spinJS2.getUrl(), //appdata.getUrl kan dus voor een get-request
                landen: {}, //zie hieronder
                talen: {}
            };

            //we halen de land- en taalcodes op en sorteren ze
            //landenlijst
            localecodes.landen().then(landen => {
                //geef de landcodes maar dan voor de landen gesorteerd:
                let landcodes = Object.keys(landen);
                landcodes.sort((a, b) => landen[a].localeCompare(landen[b]));
                appdata.landen = {};
                for (let code of landcodes) {
                    appdata.landen[code] = landen[code];
                }
            });
            //parallel de talen
            localecodes.talen(1).then(talen => {
                let taalcodes = Object.keys(talen);
                taalcodes.sort((a, b) => talen[a].localeCompare(talen[b]));
                appdata.talen = {};
                for(let code of taalcodes)
                {
                    appdata.talen[code] = talen[code];
                }
            });

            //helper voor de cached- calls: ook als het cached is altijd async teruggeven

            function asyncreturn(value) {
                return $q((resolve) => {
                    $timeout(() => {
                        resolve(value);
                    }, 1);
                });
            }

            let api = {
                init: function () {
                    //SpinJS2 service event:
                    $rootScope.$on('SpinJS2.reconnect', () => {
                        //clear de cache, want we weten niet wat we gemist hebben
                        sihwmemcache.clear();
                        this.isLoggedIn(); //check de inlogstate
                    });

                    //datawijziging van het backend
                    $rootScope.$on('api.notify.datawijziging', (_e, d) => {
                        log.debug('api.notify.datawijziging', d);
                        //d bevat een key of array van keys die in deze applicatie een datawijziging beschrijft. We clearen de cache op die key
                        let keys = d.keys || [d.key]; //kan allebei
                        sihwmemcache.clearOn(keys);
                        log.debug('Doorsturen api.datawijziging_verwerkt');
                        for (let key of keys) {
                            //nu we klaar zijn, broadcasten we de lokale wijziging. Scopes hebben dan dus geen last van nog niet bijgewerkte caches
                            $rootScope.$broadcast('api.datawijziging_verwerkt', key);
                        }
                    });

                    //check of we zijn ingelogd enzo
                    this.isLoggedIn();
                },

                /**
                 * Test de verbindiing
                 * @returns {$q.defer.promise}
                 */
                ping: function () {
                    log.debug('Ping', spinJS2.getUrl());
                    return spinJS2.ping().then(function () {
                        log.debug("Pong");
                    });
                },

                /**
                 * Check of we al zijn ingelogd in het backend, levert ook nieuwe authorisatie en userdata op
                 * @returns {promise} Promise die resolvt met true/false of reject bij grote error
                 */
                isLoggedIn: function () {
                    if (userdata && spinJS2.hasAuth()) {
                        //we geloven het wel
                        return $q.resolve(true);
                    }

                    if (this.__isLoggedInPromise) {
                        log.debug("isLoggedIn dubbel: we gebruiken de bestaande promise");
                        return this.__isLoggedInPromise; //is al bezig
                    }
                    else {
                        return (this.__isLoggedInPromise = spinJS2.send('material', 'relogin')).then(
                            data => {
                                log.log('relogin gelukt', data);
                                this._updateLogindata(data);
                                return true;
                            },
                            err => {
                                log.log('err bij relogin', err);
                                return false;
                            }).finally(() => {
                            delete this.__isLoggedInPromise;
                        });
                    }
                },

                /**
                 * inloggen met ingevoerde gebruikersnaam en wachtwoord. Via de material-controller, speciaal voor material (zonder domein)
                 * @param {string} username
                 * @param {string} password
                 * @returns {promise} promise die resolvet met true/false of reject bij grote error (spinjs2 errors worden gewrapped in resolve(false))

                 */
                login: function (username, password) {
                    this.logout(); //uitloggen
                    //spin v2
                    let data = {
                        username: username,
                        password: password
                    };
                    return spinJS2.send('material', 'login', data).then(
                        data => {
                            //gelukt? -- we krijgen een webtoken en data
                            log.debug('inloggen gelukt', data);
                            this._updateLogindata(data);
                            return true;
                        },
                        err => {
                            log.error('err bij login', err);
                            return false;
                        });
                },

                /**
                 * Verwerk de data van geslaagde login of relogin-actie
                 * @param data
                 * @private
                 */
                _updateLogindata: function (data) {
                    spinJS2.setAuth(data.authkey);  //de teruggegeven key gebruiken we voortaan
                    basis.ingelogd = true;
                    userdata = data.userdata;
                    basis.userdata = this.getUserdata(); //kopie
                    sihwmemcache.clear();
                    this._basisBijwerken();
                },

                /**
                 * Regel het bijwerken van de basisdata: haal de domeinen e.d. op Los van de userdata, zodat het bij wijzigingen opnieuw kan
                 * @private
                 */
                _basisBijwerken: function () {
                    //vooralsnog noop
                },

                /**
                 * Verwijder de data rond een ingelogde user + auth. Geen interfaceacties
                 */
                logout: function () {
                    spinJS2.deleteAuth(); //weg met de auth
                    sihwmemcache.clear();
                    $rootScope.basis = basis = {ingelogd: false}; //alles weg
                    $rootScope.menu = {
                        titel: 'Dynalearn Material'
                    };
                },

                /**
                 * Ruwe send
                 * @param controller
                 * @param actie
                 * @param data
                 */
                send: function (controller, actie, data) {
                    return spinJS2.send(controller, actie, data);
                },

                /**
                 * cachedSend: ruwe send, maar met cached data.
                 * @param deleteOn: array van deleteOnKeys of 1 key
                 * @param controller
                 * @param actie
                 * @param data
                 */
                cachedSend(deleteOn, controller, actie, data) {
                    let cacheKey = {
                        key: 'cachedSend',
                        controller: controller,
                        actie: actie,
                        data: data
                    };

                    if (!Array.isArray(deleteOn)) {
                        deleteOn = [deleteOn];
                    }
                    let cached = sihwmemcache.get(cacheKey, deleteOn);
                    if (cached) {
                        log.debug(`cachedSend cachehit voor ${controller}:${actie}`, deleteOn);
                        return asyncreturn(cached);
                    }
                    else {
                        log.debug(`cachedSend GEEN cachehit voor ${controller}:${actie}`);
                        return this.send(controller, actie, data).then(res => {
                            sihwmemcache.add(cacheKey, deleteOn, res);
                            return res;
                        }); //bij catch gewoon doorvallen
                    }
                },

                /**
                 * Zijn we op dit moment verbonden met het backend?
                 * @return {boolean}
                 */
                connected: function () {
                    return spinJS2.connected();
                },

                /**
                 * Resolve een promise zodra de verbinding met het backend okee is
                 * @return {*}
                 */
                whenConnected: function () {
                    return spinJS2.whenConnected();
                },

                /**
                 * Return de userdata van de ingelogde gebruiker (we hebben ook $rootScope.userdata, die is beter)
                 * @return {*}
                 */
                getUserdata: function () {
                    return userdata ? angular.extend({}, userdata) : false;
                },

                /**
                 * Maak een geturl voor het backend
                 * @param {string} controller
                 * @param {string} actie
                 * @param {*[]} args Argumentenarray, voeg eventueel een extra arg toe om een mooie titel in een browserscherm te krijgen
                 * @param {boolean} metAuth
                 */
                getUrl(controller, actie, args, metAuth) {
                    return spinJS2.maakGetUrl(controller, actie, args, metAuth);
                },

                /**
                 * Open een geturl voor het backend in een nieuwe tab. Argumenten als getUrl
                 * @param controller
                 * @param actie
                 * @param args
                 * @param metAuth
                 */
                openGetTab(controller, actie, args, metAuth) {
                    targetblank(this.getUrl(controller, actie, args, metAuth));
                },

                /**
                 * Open een actie in create. In een nieuwe tab of niet
                 * @param actie
                 * @param arg
                 * @param inTab
                 */
                openCreateActie(actie, arg, inTab) {
                    let url = this.createUrl(actie, arg);
                    if (inTab) {
                        targetblank(url);
                    }
                    else {
                        document.location = url;
                    }
                },
                /**
                 * Maak een url voor in create
                 * @param actie
                 * @param arg
                 */
                createUrl(actie, arg) {
                    return `${FRONTEND_URL}/#!/actie/${actie}/${arg}`;
                },


                get authKey() {
                    return spinJS2.authKey;
                }
            };
            api.init();
            return api;
        }]);
