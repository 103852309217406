/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 23-1-2018
 * Time: 19:30
 *
 *
 */

angular.module('dl.registratie', [
    'ui.bootstrap',
    'sihw.sihwlog',
    'dl.approuter',
    'sihw.alert.service'
])
    .config(['approuterProvider', function (approuterProvider) {
        approuterProvider.state('registratie',
            {
                url: '/registratie',
                openbaar: true, //onze eigen openbaar vlag
                templateUrl: 'states/registratie/registratie.html',
                controller: 'dl.registratie.RegistratieController'
            })
    }])
    .controller('dl.registratie.RegistratieController', ['$scope', '$translate', 'approuter', 'sihwlog', 'api', 'sihwalert', function ($scope, $translate, approuter, sihwlog, api, sihwalert) {
        let log = sihwlog.logLevel('debug');
        log.log('dl.registratie.RegistratieController');
        approuter.menutitel("REGISTREER.HEADER");


        $scope.doeRegistratie = function() {
            api.send("registratie", "registreer", {
                type: 'material',
                taal: $translate.use(), //taalcode
                email: $scope.form.persoonlijk.email, //apart meesturen
                persoonlijk: $scope.form.persoonlijk,
                organisatie: $scope.form.org
            }).then(() => {
                sihwalert("REGISTREER.OK.TITEL","REGISTREER.OK.TEKST").then(() => {
                    approuter.go("login");
                });
            }).catch(err => {
                sihwalert("REGISTREER.FOUT.TITEL", `REGISTREER.FOUT.${err.foutcode || "INTERN"}`); //en niet door naar login
            });
        };

    }]);