/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 12-2-2018
 * Time: 01:27
 *
 *
 */

angular.module('myApp.materiaal', [
    'sihw.sihwlog',
    'dl.approuter',
    'myApp.erroralert',
    'sihw.confirm'
]).config(['approuterProvider', function (approuterProvider) {
    approuterProvider.state('materiaal',
        {
            url: '/material/:id',
            openbaar: false, //onze eigen openbaar vlag
            templateUrl: 'states/materiaal/materiaal.html',
            controller: 'myApp.materiaal.controller'
        });
}])
    .controller('myApp.materiaal.controller', ['$scope', '$stateParams', '$translate', '$timeout', 'approuter', 'sihwlog', 'api', 'FRONTEND_URL', 'erroralert', 'sihwconfirm',
        function ($scope, $stateParams, $translate, $timeout, approuter, sihwlog, api, FRONTEND_URL, erroralert, sihwconfirm) {
            let log = sihwlog.logLevel('debug')
            log.log('myApp.materiaal.controller');
            let haalLock = false; //helper tegen dubbel halen
            approuter.menutitel("OVERZICHT.HEADER"); //tot aan de titel

            initScope();
            //ergens gaat dit mis met F5, dus we doen een timeout
            api.whenConnected().then(_ => {
                log.log('Connected!!');
                api.isLoggedIn().then(haalData);
            });

            function initScope() {
                $scope.detail = {};
                $scope.naarCreateUrl = false;
                $scope.$on('api.datawijziging_verwerkt', (_e, d) => {
                    log.debug('Materiaal - datawijziging', d, `materiaal:${$stateParams.id}`);
                    if (d === 'materiaal' || d === `materiaal:${$stateParams.id}`) {
                        haalData(true); //opnieuw halen, data is gewijzigd
                    }
                })
            }

            /**
             *
             * @param [reload] is dit een reload?
             */
            function haalData(reload) {
                if (haalLock) {
                    log.debug('haalData al bezig, skip');
                    return;
                }

                haalLock = true;
                log.debug('haalData', `materiaal:${$stateParams.id}`);
                api.cachedSend(['materiaal', `materiaal:${$stateParams.id}`], 'material', 'detail', {id: $stateParams.id}).then(res => {
                    $scope.detail = res.detail;
                    //directnaarles?
                    if ($scope.detail.lesdirect)
                    {
                        if ($scope.detail.lesdirect)
                        {
                            $scope.detail.lesdirecturl = api.getUrl('material','lesdirect',[$scope.detail.id, $scope.detail.token], false);
                        }
                    }
                    //docenten en projectmedewerkers
                    log.debug(res.detail.docenten);
                    res.detail.docenten = res.detail.docenten.map(d => d.auteur).join(", ");
                    res.detail.projectmedewerkers = res.detail.projectmedewerkers.map(d => d.auteur).join(", ");

                    //editors, alleen als beschikbaar (eigenaar of admin)
                    if ($scope.detail.editors && $scope.detail.editors.length) {
                        $scope.detail.editornamen = $scope.detail.editors.map(ed => ed.naam).join(', ');
                    }

                    //schooltypen even mooi maken
                    res.detail.schooltypen = (res.detail.schooltypen || []).map(
                        st => {
                            let typerij = {
                                schooltype: st.schooltype
                            };
                            if (st.leerjaar === "")
                            {
                                //leerjaar nvt
                                typerij.leerjaar = $translate.instant("NVT");
                            }
                            else if (! st.leerjaar)
                            {
                                typerij.leerjaar= ""; //niets
                            }
                            else {
                                typerij.leerjaar = st.leerjaar.split(/\s*,\s*/).join(", ");
                            }
                            return typerij;
                        }
                    );
                    log.debug($scope.detail);

                    if (res.detail.modelleerniveau && (! res.detail.modelleerniveau.match(/^\s*$/)))
                    {
                        $scope.detail.modelleerniveau = res.detail.modelleerniveau.split(/\s*,\s*/).map(niveau => $translate.instant(`OVERZICHT.MODELLEERNIVEAU_${niveau}`)).join(', ');
                    }
                    else if (res.detail.modelleerniveau.match(/^\s*$/))
                    {
                        $scope.detail.modelleerniveau = $translate.instant("NVT");
                    }
                    else {
                        $scope.detail.modelleerniveau = ""; //niets
                    }

                    approuter.menutitel(res.detail.titel);
                    for (let doc of res.detail.doc) {
                        //eigenlijk alleen nodig voor afbeeldingen, maar goed
                        doc.url = api.getUrl("material", "doc", [doc.id, res.detail.token, doc.naam], false);
                    }
                    for (let model of $scope.detail.model) {
                        model.img = model.heeftimage && api.getUrl('material', 'modelimg', [model.id, res.detail.token, model.titel], false); //naam erachter voor de titel
                    }

                    //verwerk rich text als dat het nog niet is
                    for(let key of ['omschrijving','leerdoel','werkwijze'])
                    {
                        if ($scope.detail[key] && (! $scope.detail[key].includes('<')))
                        {
                            //geen html
                            $scope.detail[key] = $scope.detail[key].replace(/(\n|\r|\r\n|\n\r)/g, "<br>");
                        }
                    }

                    $scope.naarCreateUrl = false; //geen url voor openenem van modellen
                    haalLock = false;
                }).catch(err => {
                    log.error(err);
                    if (reload) {
                        //dan gaan we vast wel al iets doen
                        return;
                    }
                    erroralert(err).then(() => {
                        approuter.go('splash'); //hier weg
                        haalLock = false;
                    });
                });
            }

            /**
             * Klik op een document om te openen
             * @deprecated - we gebruiken weer gewoon een get-url met een kort token
             */
            $scope.openDoc = function(doc) {
                log.debug(`opendoc`,doc);
                api.send('material','docdata',{id: doc.id}).then(data => {
                    let blob = new Blob([new Uint8Array(data.doc.data ?? data.doc)],{type: doc.mimetype});
                    let url = window.URL.createObjectURL(blob);
                    window.open(url);
                    //we geven je tien minuten de tijd om het doc te downloaden, daarna mag de gc aan het werk (als de app er nog is
                    setTimeout(() => {
                        this.log.debug(`Garbage collect objecturl voor document`, url);
                        window.URL.revokeObjectURL(url);
                    }, 10 * 60 * 1000);
                });
            }

            /**
             * Toggle selectie van model voor openen in create
             */
            $scope.toggleModelCreate = function () {
                let geselecteerd = $scope.detail.model.filter(m => m.geselecteerd).map(m => m.id);
                if (!(geselecteerd && geselecteerd.length)) {
                    $scope.naarCreateUrl = false;
                } else {
                    $scope.naarCreateUrl = api.createUrl('saveMaterial', btoa(JSON.stringify({
                        auth: api.authKey,
                        models: geselecteerd
                    })));
                }
                log.debug('modelselectie', geselecteerd, $scope.naarCreateUrl);
            };

            /**
             * Verwijder - na bevestiging - dit model
             */
            $scope.verwijder = function () {
                sihwconfirm(
                    $translate.instant('OVERZICHT.BEVESTIG_VERWIJDEREN'),
                    $translate.instant($scope.detail.is_voorkennis ? 'OVERZICHT.MAG_VERWIJDEREN_VOORKENNIS' : 'OVERZICHT.MAG_VERWIJDEREN_GEENVOORKENNIS'
                    ),
                    $translate.instant('ALG.VERWIJDEREN'),
                    $translate.instant('ALG.CANCEL'),
                    'materiaalconfirmverwijderen'
                ).then(verwijderen => {
                    if (verwijderen) {
                        api.send('material', 'verwijder', {id: $scope.detail.id}).then(() => {
                            approuter.go('overzicht');
                        }).catch(erroralert);
                    }
                });
            };
        }]);
